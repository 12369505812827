import React, {useState, useEffect} from 'react';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import findKey from 'lodash/findKey';
import Preview from './Preview.jsx';

const VariationSelector = ({font}) => {
  const [fontVariation, setFontVariation] = useState();
  const [variationName, setVariationName] = useState();
  const [variationSettings, setVariationSettings] = useState({});

  useEffect(() => {
    let defaultSettings = mapValues(font.variationAxes, 'default');
    let defaultVariationName = findKey(font.namedVariations, n => isEqual(font.namedVariations[n], defaultSettings))
      || font.subfamilyName
      || 'Custom';

    if (Object.keys(defaultSettings).length > 0) {
      let newFontVariation = font.getVariation(defaultSettings);
      setFontVariation(newFontVariation);
    } else {
      setFontVariation(font);
    }

    setVariationName(defaultVariationName);
    setVariationSettings(defaultSettings);
  }, []);

  const onChange = (e) => {
      setFontVariation(font.getVariation(e.target.value));
      setVariationName(e.target.value);
      setVariationSettings(font.namedVariations[e.target.value]);
  }

  const onAxisChange = (tag, e) => {
    const settings = Object.assign({}, variationSettings, {
      [tag]: e.target.value
    });

    setFontVariation(font.getVariation(settings));
    setVariationName(null);
    setVariationSettings(settings);
  };

  let axes = font.variationAxes;

  return (
    <div className="variation-selector">
      {Object.keys(font.namedVariations).length > 0 &&
        <select onChange={onChange} value={variationName || ''} key={variationName}>
          {Object.keys(font.namedVariations).map(name =>
            <option key={name}>{name}</option>
          )}
          {!font.namedVariations[variationName] && <option disabled>{variationName || 'Custom'}</option>}
        </select>
      }
      {Object.keys(axes).map(tag =>
        <div className="axis" key={axes[tag].name}>
          <label>{axes[tag].name}:</label>
          <input
            type="range"
            min={axes[tag].min}
            max={axes[tag].max}
            step={0.001}
            value={variationSettings[tag] || ''}
            onInput={onAxisChange.bind(this, tag)}
            onChange={onAxisChange.bind(this, tag)}
             />
        </div>
      )}
      {fontVariation &&
        <Preview font={fontVariation} />
      }
    </div>
  );
}

export default VariationSelector;
