import React, {useState, useEffect} from 'react';
import PreviewCanvas from './PreviewCanvas.jsx';

const Preview = ({font}) => {
  const [text, setText] = useState('Hello world!');
  const [fontSize, setFontSize] = useState(50);
  const [features, setFeatures] = useState({});
  const [script, setScript] = useState('');
  const [language, setLanguage] = useState('');
  const [direction, setDirection] = useState('');

  const onTextChange = (e) => {
    setText(e.target.value);
  }

  const onFontSizeChange = (e) => {
    setFontSize(e.target.value);
  }

  const onScriptChange = (e) => {
    setScript(e.target.value);
    setLanguage('');
    setFeatures({});
  }

  const onLangChange = (e) => {
    setLanguage(e.target.value);
    setFeatures({});
  }

  const onFeatureChange = (feature, e) => {
    setFeatures({...features, [feature]: e.target.checked});
  }

  const onDirChange = (e) => {
    setDirection(e.target.value);
  }

  let run = font.layout(text, features, script, language, direction);

  let scripts = (font.GSUB ? font.GSUB.scriptList : []).concat(font.GPOS ? font.GPOS.scriptList : []);
  let scriptTags = Array.from(new Set(scripts.map(s => s.tag)));
  let selectedScript = scripts.find(s => s.tag === run.script);
  let languages = selectedScript ? selectedScript.script.langSysRecords : [];
  let directions = ["ltr", "rtl"];

  return (
    <div className="preview">
      <PreviewCanvas run={run} text={text} font={font} fontSize={fontSize} features={features} />
      <div className="text-input">
        <input type="text" value={text} onInput={onTextChange} onChange={onTextChange} />
      </div>
      <div className="font-size">
        <label>Size:</label>
        <input type="range" min={8} max={240} value={fontSize} onInput={onFontSizeChange} onChange={onFontSizeChange} />
      </div>

      <div className="feature-selector">
        <label>Script:</label>
        <select onChange={onScriptChange} value={run.script || ''}>
          {scriptTags.map(script =>
            <option key={script}>{script}</option>
          )}
        </select>

        <label>Language:</label>
        <select onChange={onLangChange} value={run.language || ''}>
          <option>Default</option>
          {languages.map(lang =>
            <option value={lang.tag} key={lang.tag}>{lang.tag}</option>
          )}
        </select>

        <label>Direction:</label>
        <select onChange={onDirChange} value={run.direction || ''}>
          <option value=''>Default</option>
          {directions.map(direction =>
            <option key={direction}>{direction}</option>
          )}
        </select>

        <div className="features">
          {font.getAvailableFeatures(run.script, run.language).map(feat =>
            <label key={feat}><input type="checkbox" checked={run.features[feat]} onChange={onFeatureChange.bind(this, feat)} /> {feat}</label>
          )}
        </div>
      </div>
    </div>
  );
}

export default Preview;
