import 'isomorphic-fetch';
import 'core-js/stable';
import 'regenerator-runtime';
import React, {useState, useEffect} from 'react';
import {render} from 'react-dom';
import fontkit from 'fontkit';
import blobToBuffer from 'blob-to-buffer';
import VariationSelector from './VariationSelector.jsx';

const App = () => {
  const [font, setFont] = useState();

  useEffect(() => {
    const loadFont = async () => {
      const fontFile = await fetch('/assets/fonts/variable/Llawen-VF.ttf');
      const fontBlob = await fontFile.blob();
      blobToBuffer(fontBlob, (err, buffer) => {
        setFont(fontkit.create(buffer));
      });
    };
    loadFont();
  }, []);

  return (
    <div className="app">
      <h1>Llawen - variable font demo</h1>
      {font &&
        <VariationSelector font={font} />
      }
    </div>
  )
};

render(<App />, document.getElementById('app-container'));