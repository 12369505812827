import React, { useState, useRef, useEffect} from 'react';

const PreviewCanvas = ({font, run, fontSize, width, height}) => {
  const ctx = useRef();
  const [canvas, setCanvas] = useState();

  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    setRatio(window.devicePixelRatio || 1);
  }, [])

  useEffect(() => {
    if(ctx.current) {
      setCanvas(ctx.current.getContext('2d'));
    }
  }, [ctx]);

  useEffect(() => {
    if(canvas) {
    draw(canvas, font, run, fontSize, width, height);
    }
  }, [canvas, font, run, fontSize, width, height]);

  const draw = (ctx, font, run, fontSize, width, height) => {
    if (!ctx) return;
    ctx.save();
    ctx.scale(ratio, ratio);
    ctx.clearRect(0, 0, width, height);

    let scale = 1 / font.unitsPerEm * fontSize;
    let x = 0;
    let y = 0;

    ctx.translate(0, 200);
    ctx.scale(1, -1);

    run.glyphs.forEach((glyph, index) => {
      let pos = run.positions[index];
      ctx.save();
      ctx.translate((x + pos.xOffset) * scale, (y + pos.yOffset) * scale);
      ctx.beginPath();
      glyph.render(ctx, fontSize);
      ctx.restore();

      x += pos.xAdvance;
      y += pos.yAdvance;
    });

    ctx.restore();
  }

  return (
    <canvas
      width={width * ratio}
      height={height * ratio}
      style={{width, height}}
      ref={ctx} />
  );
}

PreviewCanvas.defaultProps = {
  width: 800,
  height: 240
};


export default PreviewCanvas;
